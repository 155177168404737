<template>
  <v-container>
    <v-card outlined class="pa-2 mt-0" :loading="loading">
      <v-row>
        <v-col cols="12" md="12">
          <div class="text-center">
            <div class="normal16">
              สมัครเข้ารับการคัดเลือกเพื่อบรรจุและแต่งตั้งให้ดำรงตำแหน่ง
              ผู้อำนวยการสถานศึกษา
            </div>
            <div class="normal14">
              สังกัดสำนักงานคณะกรรมการการอาชีวศึกษา
            </div>

            <div
              type="info"
              outlined
              v-if="sedirector_apps.sedirector_app_type === 'inside'"
            >
              <div>
                <u class="red--text bold14">ยกเว้นเขต</u>
                พัฒนาพิเศษเฉพาะกิจจังหวัดชายแดนภาคใต้
              </div>
            </div>

            <div
              type="info"
              outlined
              v-if="sedirector_apps.sedirector_app_type === 'outside'"
            >
              <div>
                <div class="normal14">
                  เฉพาะจังหวัดยะลา นราธิวาส ปัตตานี และ 4 อำเภอ ในจังหวัดสงขลา
                </div>
                <div class="normal14">
                  (อำเภอจะนะ อำเภอสะบ้าย้อย อำเภอเทพา และอำเภอนาทวี)
                </div>
              </div>
            </div>
          </div>
          <div class="text-center warning--text bold14">
            <div>ประจำปี {{ period_directors.period_sedirector_year }}</div>
            <div>
              รับสมัครระหว่างวันที่
              {{
                period_directors.period_sedirector_start
                  | moment("add", "543 years")
                  | moment("D MMMM YYYY")
              }}
              ถึง
              {{
                period_directors.period_sedirector_stop
                  | moment("add", "543 years")
                  | moment("D MMMM YYYY")
              }}
            </div>
            <!--   <div>
              {{ sedirector_app_idref }}
            </div> -->
          </div>
        </v-col>
        <v-col cols="12" md="12">
          <v-row justify="center" no-gutters>
            <v-col md="1"></v-col>
            <v-col md="10">
              <v-stepper v-model="steps" vertical>
                <v-stepper-step :complete="steps > 1" step="1" class="bold14">
                  ตรวจสอบ ข้อมูลพื้นฐาน
                </v-stepper-step>

                <v-stepper-content step="1">
                  <v-alert outlined color="">
                    <div class="normal14">
                      ชื่อ-นามสกุล :
                      <u class="bold14 pa-5">{{ users.frist_names }}</u>
                    </div>

                    <div class="font-weight-bold">
                      <h3>ประสงค์สมัครสอบในเขตพื้นที่</h3>
                      <v-radio-group v-model="sedirector_app_type">
                        <v-radio value="inside">
                          <template v-slot:label>
                            <div>
                              <strong class="primary--text">ยกเว้นเขต</strong
                              >พัฒนาพิเศษเฉพาะกิจจังหวัดชายแดนภาคใต้ (อำเภอจะนะ
                              อำเภอสะบ้าย้อย อำเภอเทพา และอำเภอนาทวี)
                            </div>
                          </template>
                        </v-radio>
                        <v-radio value="outside">
                          <template v-slot:label>
                            <div>
                              <strong class="red--text">ในเขต</strong
                              >พัฒนาพิเศษเฉพาะกิจจังหวัดชายแดนภาคใต้ (อำเภอจะนะ
                              อำเภอสะบ้าย้อย อำเภอเทพา และอำเภอนาทวี)
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </div>
                    <div class="red--text font-weight-bold">
                      * เมื่อกดปุ่มดำเนินการต่อ ระบบจะทำการสร้าง
                      <u class="warning--text pa-2"> ใบสมัครชั่วคราว</u
                      >ให้ทันทีในระบบ
                    </div>
                  </v-alert>
                  <v-btn color="primary" @click="addsedirectorSubmit()">
                    ดำเนินการต่อ
                  </v-btn>
                </v-stepper-content>

                <v-stepper-step :complete="steps > 2" step="2">
                  ตรวจสอบ คุณวุฒิการศึกษา
                </v-stepper-step>

                <v-stepper-content step="2">
                  <v-card outlined>
                    <v-simple-table class="normal14">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              ระดับการศึกษา
                            </th>
                            <th class="text-left">
                              สาขาวิชา
                            </th>
                            <th class="text-left">
                              จบจาก
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in personnel_educations"
                            :key="item.id"
                          >
                            <td>{{ item.education_level }}</td>
                            <td>
                              {{ item.faculty_name }} : {{ item.branch_name }}
                            </td>
                            <td>
                              {{ item.academy_name }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                  <v-btn
                    color="primary"
                    @click="sedirector_appsSubmit((step = 2))"
                  >
                    ดำเนินการต่อ
                  </v-btn>
                  <v-btn outlined @click="deletesedirectorSubmit()">
                    ยกเลิกขั้นตอนนี้
                  </v-btn>
                </v-stepper-content>

                <v-stepper-step :complete="steps > 3" step="3">
                  ตรวจสอบ การบรรจุรับราชการ
                </v-stepper-step>

                <v-stepper-content step="3">
                  <v-alert outlined color="" class="normal14">
                    วันเดือนปี ที่บรรจุ :
                    <u class="bold14 pa-5">{{ users.date_app }}</u> <br />
                    อายุราชการ :<u class="bold14 pa-5"> {{ get_gov_Age }}</u>
                  </v-alert>
                  <v-btn
                    color="primary"
                    @click="sedirector_appsSubmit((step = 3))"
                  >
                    ดำเนินการต่อ
                  </v-btn>
                  <v-btn outlined @click="sedirector_appsSubmit((step = 1))">
                    ยกเลิกขั้นตอนนี้
                  </v-btn>
                </v-stepper-content>

                <v-stepper-step :complete="steps > 4" step="4">
                  ตรวจสอบ การดำรงตำแหน่ง-ข้อมูลติดต่อ
                </v-stepper-step>
                <v-stepper-content step="4">
                  <v-alert outlined>
                    <div class="normal14">
                      <div>
                        ปัจจุบันดำรงตำแหน่ง :
                        <u class="bold14 pa-5">{{ users.position_name }}</u>
                        อันดับ/ระดับ :
                        <u class="bold14 pa-5">{{ users.rang_level }}</u>
                        วิทยฐานะ :<u class="bold14 pa-5">
                          {{ users.rang_name }}</u
                        >
                        ค.ศ. : <u class="bold14 pa-5">{{ users.rang_level }}</u>
                      </div>
                      <div>
                        เงินเดือน :
                        <u class="bold14 pa-5">{{ users.salary_s }}</u> บาท
                      </div>
                      <div>
                        สถานศึกษา :<u class="bold14 pa-5">
                          {{ users.college_name }}</u
                        >
                      </div>
                      <div>
                        {{ users.collegeinfo_address }}
                      </div>
                      <div v-if="users.veiVocalName">
                        {{ "สถาบันอาชีวศึกษา" + users.veiVocalName }}
                      </div>
                      <div>
                        ดำรงตำแหน่งปัจจุบันเมื่อวันที่
                        <u class="bold14 pa-5"
                          >{{
                            users.date_app_positonnow
                              | moment("add", "543 years")
                              | moment("D MMMM YYYY")
                          }}
                        </u>
                      </div>
                      <div>
                        รวมระยะเวลาที่ดำรงตำแหน่งปัจจุบัน :
                        <u class="bold14 pa-5"> {{ get_Appoint_Age }}</u>
                      </div>
                    </div>
                  </v-alert>
                  <v-btn
                    color="primary"
                    @click="sedirector_appsSubmit((step = 4))"
                  >
                    ดำเนินการต่อ
                  </v-btn>
                  <v-btn outlined @click="sedirector_appsSubmit((step = 2))">
                    ยกเลิกขั้นตอนนี้
                  </v-btn>
                </v-stepper-content>

                <v-stepper-step :complete="steps > 5" step="5">
                  ตรวจสอบ การปฏิบัติหน้าที่ในปัจจุบัน
                </v-stepper-step>
                <v-stepper-content step="5">
                  <v-card outlined class="pa-5">
                    <v-radio-group
                      v-model="sedirector_apps.sedirector_app_position"
                    >
                      <v-radio value="teach">
                        <template v-slot:label>
                          <div
                            style="border: 1px grey solid; border-radius: 5px;"
                          >
                            <div class="ma-2">
                              <strong class="success--text"
                                >ครูผู้สอน/ศึกษานิเทศก์/บุคลากรทางการศึกษาอื่นตามมาตรา
                                38 ค (2)</strong
                              >
                            </div>
                            <div
                              class="normal14 bluetext ma-2"
                              v-if="
                                sedirector_apps.sedirector_app_position ===
                                  'teach'
                              "
                            >
                              ตั้งแต่วันที่
                              {{
                                get_app_dmy
                                  | moment("add", "543 years")
                                  | moment("D MMMM YYYY")
                              }}
                              ถึงวันที่
                              {{
                                date_end_cal
                                  | moment("add", "543 years")
                                  | moment("D MMMM YYYY")
                              }}
                              รวมระยะเวลา :
                              {{ get_gov_Age }}
                            </div>
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="head">
                        <template v-slot:label>
                          <div
                            style="border: 1px grey solid; border-radius: 5px;"
                          >
                            <div class="ma-2">
                              <strong class="success--text"
                                >หัวหน้าแผนกวิชา หรือหัวหน้างาน
                                หรือหัวหน้ากลุ่มงานในหน่วยศึกษานิเทศก์</strong
                              >
                            </div>
                            <div
                              class="normal14 bluetext ma-2"
                              v-if="
                                sedirector_apps.sedirector_app_position ===
                                  'head'
                              "
                            >
                              <v-row no-gutters>
                                <v-col cols="12" md="6">
                                  <v-dialog
                                    ref="dialog"
                                    v-model="modal"
                                    :return-value.sync="date"
                                    persistent
                                    width="290px"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-model="
                                          sedirector_apps.sedirector_app_start
                                        "
                                        label="ตั้งแต่วันที่"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="
                                        sedirector_apps.sedirector_app_start
                                      "
                                      scrollable
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="modal = false"
                                      >
                                        Cancel
                                      </v-btn>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="
                                          $refs.dialog.save(date),
                                            get_Appoint5_Age()
                                        "
                                      >
                                        OK
                                      </v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-dialog
                                    ref="dialog"
                                    v-model="modal2"
                                    :return-value.sync="date"
                                    persistent
                                    width="290px"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-model="
                                          sedirector_apps.sedirector_app_end
                                        "
                                        label="ตั้งแต่วันที่"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="
                                        sedirector_apps.sedirector_app_end
                                      "
                                      scrollable
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="modal2 = false"
                                      >
                                        Cancel
                                      </v-btn>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="
                                          $refs.dialog.save(date),
                                            get_Appoint5_Age()
                                        "
                                      >
                                        OK
                                      </v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-col>
                                <v-col cols="12" md="12">
                                  รวมระยะเวลา {{ get_Appoint5_Ages }}
                                </v-col>
                              </v-row>
                            </div>
                          </div>
                        </template>
                      </v-radio>

                      <v-radio value="director">
                        <template v-slot:label>
                          <div
                            style="border: 1px grey solid; border-radius: 5px;"
                          >
                            <div class="ma-2">
                              <strong class="success--text"
                                >ทำหน้าที่ผู้อำนวยการสถานศึกษา
                                โดยคำสั่งสถานศึกษา</strong
                              >
                            </div>
                            <div
                              class="normal14 bluetext ma-2"
                              v-if="
                                sedirector_apps.sedirector_app_position ===
                                  'director'
                              "
                            >
                              <v-row no-gutters>
                                <v-col cols="12" md="6">
                                  <v-dialog
                                    ref="dialog"
                                    v-model="modal3"
                                    :return-value.sync="date"
                                    persistent
                                    width="290px"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-model="
                                          sedirector_apps.sedirector_app_start
                                        "
                                        label="ตั้งแต่วันที่"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="
                                        sedirector_apps.sedirector_app_start
                                      "
                                      scrollable
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="modal3 = false"
                                      >
                                        Cancel
                                      </v-btn>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="
                                          $refs.dialog.save(date),
                                            get_Appoint5_Age()
                                        "
                                      >
                                        OK
                                      </v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-dialog
                                    ref="dialog"
                                    v-model="modal4"
                                    :return-value.sync="date"
                                    persistent
                                    width="290px"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-model="
                                          sedirector_apps.sedirector_app_end
                                        "
                                        label="ตั้งแต่วันที่"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="
                                        sedirector_apps.sedirector_app_end
                                      "
                                      scrollable
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="modal4 = false"
                                      >
                                        Cancel
                                      </v-btn>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="
                                          $refs.dialog.save(date),
                                            get_Appoint5_Age()
                                        "
                                      >
                                        OK
                                      </v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-col>
                                <v-col cols="12" md="12">
                                  รวมระยะเวลา {{ get_Appoint5_Ages }}
                                </v-col>
                              </v-row>
                            </div>
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-card>
                  <v-btn
                    color="primary"
                    v-if="sedirector_apps.sedirector_app_position"
                    @click="sedirector_appsSubmit((step = 5))"
                  >
                    ดำเนินการต่อ
                  </v-btn>
                  <v-btn outlined @click="sedirector_appsSubmit((step = 3))">
                    ยกเลิกขั้นตอนนี้
                  </v-btn>
                </v-stepper-content>

                <v-stepper-step :complete="steps > 6" step="6">
                  ข้อมูล การรักษาวินัย
                </v-stepper-step>
                <v-stepper-content step="6">
                  <v-card outlined class="pa-5">
                    <v-radio-group
                      v-model="sedirector_apps.sedirector_app_discipline"
                    >
                      <v-radio value="ever">
                        <template v-slot:label>
                          <div>
                            <strong class="warning--text"
                              >เคยถูกลงโทษทางวินัย</strong
                            >
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="never">
                        <template v-slot:label>
                          <div>
                            <strong class="primary--text"
                              >ไม่เคยถูกลงโทษทางวินัย</strong
                            >
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-card>
                  <v-btn
                    color="primary"
                    v-if="sedirector_apps.sedirector_app_discipline"
                    @click="sedirector_appsSubmit((step = 6))"
                  >
                    ยืนยันสมัคร
                  </v-btn>
                  <v-btn outlined @click="sedirector_appsSubmit((step = 4))">
                    ยกเลิกขั้นตอนนี้
                  </v-btn>
                </v-stepper-content>

                <v-stepper-step :complete="steps > 7" step="7">
                  การปฏิบัติงานในเขตพัฒนาพิเศษ
                </v-stepper-step>
                <v-stepper-content step="7">
                  <v-card
                    outlined
                    class="pa-5"
                    v-if="sedirector_apps.sedirector_app_type != 'inside'"
                  >
                    <div>
                      การปฏิบัติงานในเขตพัฒนาพิเศษเฉพาะกิจจังหวัดชายแดนภาคใต้เฉพาะจังหวัดยะลา
                      นราธิวาส ปัตตานี และ 4 อำเภอ ในจังหวัดสงขลา (อำเภอจะนะ
                      อำเภอสะบ้าย้อย อำเภอเทพา และอำเภอนาทวี)
                    </div>
                    <v-radio-group v-model="souths">
                      <v-radio value="ever">
                        <template v-slot:label>
                          <div>
                            <strong class="warning--text"
                              >ไม่เคยปฏิบัติงานในพื้นที่</strong
                            >
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="never">
                        <template v-slot:label>
                          <div>
                            <div>
                              <strong class="primary--text"
                                >ปฏิบัติ หรือ เคยปฏิบัติงานในพื้นที่</strong
                              >
                            </div>

                            <div
                              class="normal14 bluetext ma-2"
                              v-if="souths === 'never'"
                            >
                              <v-row no-gutters>
                                <v-col cols="12" md="6">
                                  <v-dialog
                                    ref="menu5"
                                    v-model="menu5"
                                    persistent
                                    :return-value.sync="date"
                                    width="290px"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-model="
                                          sedirector_apps.sedirector_app_southdatebegin
                                        "
                                        label="ตั้งแต่วันที่"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="
                                        sedirector_apps.sedirector_app_southdatebegin
                                      "
                                      no-title
                                      scrollable
                                      locale="TH-th"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="menu5 = false"
                                      >
                                        Cancel
                                      </v-btn>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.menu5.save(date)"
                                      >
                                        OK
                                      </v-btn>
                                    </v-date-picker>
                                  </v-dialog></v-col
                                >
                                <v-col cols="12" md="6">
                                  <v-dialog
                                    ref="menu6"
                                    v-model="menu6"
                                    persistent
                                    :return-value.sync="date"
                                    width="290px"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-model="
                                          sedirector_apps.sedirector_app_southend
                                        "
                                        label="ถึงวันที่"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        @change="get_Appoin7_Age()"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="
                                        sedirector_apps.sedirector_app_southend
                                      "
                                      no-title
                                      scrollable
                                      locale="TH-th"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="menu6 = false"
                                      >
                                        Cancel
                                      </v-btn>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="
                                          $refs.menu6.save(date),
                                            get_Appoin7_Age()
                                        "
                                      >
                                        OK
                                      </v-btn>
                                    </v-date-picker>
                                  </v-dialog></v-col
                                >
                                <v-col cols="12" md="6">
                                  <v-select
                                    v-model="
                                      sedirector_apps.sedirector_app_southposition
                                    "
                                    prepend-icon="mdi-account-convert"
                                    label="ในตำแหน่ง"
                                    :items="positions"
                                    item-text="text"
                                    item-value="value"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-autocomplete
                                    v-model="
                                      sedirector_apps.sedirector_app_southcollege
                                    "
                                    prepend-icon="mdi-domain"
                                    label="สังกัด"
                                    :items="colleges"
                                    item-text="college_name"
                                    item-value="college_code"
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="12">
                                  รวมระยะเวลา {{ get_Appoint7_Ages }}
                                </v-col>
                              </v-row>
                            </div>
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-card>
                  <v-btn
                    color="primary"
                    v-if="souths"
                    @click="sedirector_appsSubmit((step = 7))"
                  >
                    ยืนยันสมัคร
                  </v-btn>
                  <v-btn outlined @click="sedirector_appsSubmit((step = 5))">
                    ยกเลิกขั้นตอนนี้
                  </v-btn>
                </v-stepper-content>

                <v-stepper-step :complete="steps > 8" step="8">
                  แนบรูปถ่าย / พิมพ์ใบสมัคร / แนบเอกสารประกอบการสมัคร
                </v-stepper-step>
                <v-stepper-content step="8">
                  <div class="ma-10">
                    <div>
                      <v-simple-table>
                        <thead>
                          <tr>
                            <th style="width:20%" class="text-center">
                              รายการ
                            </th>

                            <th style="width:50%" class="text-center">
                              ไฟล์แนบ
                            </th>
                            <th style="width:30%" class="text-center">
                              ดำเนินการ
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="pa-2 text-center">
                              รูปถ่าย
                            </td>
                            <td class="pa-2">
                              <div
                                class="text-center"
                                v-if="
                                  String(sedirector_apps.sedirector_app_image)
                                    .length > 6
                                "
                              >
                                <v-avatar size="80px">
                                  <img
                                    @click="
                                      pdfHrvecRegisDir(
                                        sedirector_apps.sedirector_app_image
                                      )
                                    "
                                    :src="
                                      '/HrvecRegisDir/' +
                                        sedirector_apps.sedirector_app_image
                                    "
                                  />
                                </v-avatar>
                              </div>
                              <div v-else>
                                <v-form
                                  ref="fileapplicationform_sedirector_app_image"
                                  lazy-validation
                                >
                                  <v-file-input
                                    v-model="sedirector_app_image"
                                    accept="image/png, image/jpeg"
                                    name="sedirector_app_image"
                                    color="deep-purple accent-4"
                                    counter
                                    label="ไฟล์รูป .jpg"
                                    placeholder="รูปผู้สมัคร"
                                    prepend-icon="mdi-paperclip"
                                    outlined
                                    rounded
                                    :rules="rules5mb"
                                    :show-size="1000"
                                  >
                                    <template
                                      v-slot:selection="{ index, text }"
                                    >
                                      <v-chip
                                        v-if="index < 2"
                                        color="deep-purple accent-4"
                                        dark
                                        label
                                        small
                                      >
                                        {{ text }}
                                      </v-chip>

                                      <span
                                        v-else-if="index === 2"
                                        class="text-overline grey--text text--darken-3 mx-2"
                                      >
                                        +{{ files.length - 2 }} File(s)
                                      </span>
                                    </template>
                                  </v-file-input>
                                </v-form>
                              </div>
                            </td>
                            <td class="pa-2 text-center">
                              <div
                                v-if="
                                  String(sedirector_apps.sedirector_app_file)
                                    .length > 6
                                "
                              >
                                <v-chip outlined color="warning"
                                  >แนบไฟล์ใบสมัครแล้วไม่สามารถยกได้</v-chip
                                >
                              </div>
                              <div v-else>
                                <v-btn
                                  @click="deletesedirector_app_image()"
                                  v-if="sedirector_apps.sedirector_app_image"
                                  rounded
                                  color="red"
                                  fab
                                  smalll
                                  ><v-icon>mdi-delete</v-icon>
                                </v-btn>
                                <v-btn
                                  v-else
                                  @click="
                                    fileapplicationform_sedirector_app_imageSubmit()
                                  "
                                  rounded
                                  color="primary"
                                >
                                  <v-icon>mdi-cloud-upload</v-icon>
                                  Upload</v-btn
                                >
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </div>
                  </div>

                  <!--   <hr />
                  <div
                    v-if="
                      String(sedirector_apps.sedirector_app_image).length > 6
                    "
                    class="text-center mt-5"
                  >
                    <v-btn
                      color="info"
                      :to="
                        '/user/print_app_sedirector_rig/?id=' +
                          sedirector_apps.sedirector_app_idref
                      "
                      target="_blank"
                    >
                      <v-icon>mdi-printer</v-icon> พิมพ์ใบสมัคร
                    </v-btn>
                    <h4 class="red--text">
                      *พิมพ์ใบสมัคร เสนอผู้อำนวยการวิทยาลัย
                      หลังจากที่ลงนามรับรองแล้วให้ดำเนินการสแกนไฟล์แนบ
                    </h4>
                  </div>
 -->
                  <div
                    class="ma-5"
                    v-if="
                      String(sedirector_apps.sedirector_app_image).length > 6
                    "
                  >
                    <div>
                      <v-simple-table>
                        <thead>
                          <tr>
                            <th style="width:20%" class="text-center">
                              รายการ
                            </th>

                            <th style="width:50%" class="text-center">
                              ไฟล์แนบ
                            </th>
                            <th style="width:30%" class="text-center">
                              ดำเนินการ
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="pa-2 text-center">
                              ใบสมัคร
                            </td>
                            <td class="pa-2">
                              <div
                                class="text-center"
                                v-if="sedirector_apps.sedirector_app_file"
                              >
                                <v-btn
                                  rounded
                                  color="info"
                                  @click="
                                    pdfHrvecRegisDir(
                                      sedirector_apps.sedirector_app_file
                                    )
                                  "
                                  ><v-icon>mdi-file-document</v-icon>
                                  ตรวจสอบ</v-btn
                                >
                              </div>
                              <div v-else>
                                <v-form
                                  ref="fileapplicationform_sedirector_app_file"
                                  lazy-validation
                                >
                                  <v-file-input
                                    v-model="sedirector_app_file"
                                    accept=".pdf"
                                    name="sedirector_app_file"
                                    color="deep-purple accent-4"
                                    counter
                                    label="ไฟล์ .pdf"
                                    placeholder="ใบสมัคร"
                                    prepend-icon="mdi-paperclip"
                                    outlined
                                    rounded
                                    :rules="rules5mb"
                                    :show-size="1000"
                                  >
                                    <template
                                      v-slot:selection="{ index, text }"
                                    >
                                      <v-chip
                                        v-if="index < 2"
                                        color="deep-purple accent-4"
                                        dark
                                        label
                                        small
                                      >
                                        {{ text }}
                                      </v-chip>

                                      <span
                                        v-else-if="index === 2"
                                        class="text-overline grey--text text--darken-3 mx-2"
                                      >
                                        +{{ files.length - 2 }} File(s)
                                      </span>
                                    </template>
                                  </v-file-input>
                                </v-form>
                              </div>
                            </td>
                            <td class="pa-2 text-center">
                              <v-btn
                                @click="deletesedirector_app_file()"
                                v-if="sedirector_apps.sedirector_app_file"
                                rounded
                                color="red"
                                fab
                                smalll
                                ><v-icon>mdi-delete</v-icon>
                              </v-btn>
                              <v-btn
                                v-else
                                @click="
                                  fileapplicationform_sedirector_app_fileSubmit()
                                "
                                rounded
                                color="primary"
                              >
                                <v-icon>mdi-cloud-upload</v-icon>
                                Upload</v-btn
                              >
                            </td>
                          </tr>
                          <tr>
                            <td class="pa-2 text-center">
                              เอกสารภาค ก
                            </td>

                            <td class="pa-2">
                              <div
                                class="text-center"
                                v-if="sedirector_apps.sedirector_app_file_A"
                              >
                                <v-btn
                                  rounded
                                  color="info"
                                  @click="
                                    pdfHrvecRegisDir(
                                      sedirector_apps.sedirector_app_file_A
                                    )
                                  "
                                  ><v-icon>mdi-file-document</v-icon>
                                  ตรวจสอบ</v-btn
                                >
                              </div>
                              <div v-else>
                                <v-form
                                  ref="fileapplicationform_sedirector_app_file_A"
                                  lazy-validation
                                >
                                  <v-file-input
                                    v-model="sedirector_app_file_A"
                                    accept=".pdf"
                                    name="sedirector_app_file_A"
                                    color="deep-purple accent-4"
                                    counter
                                    label="ไฟล์ .pdf"
                                    placeholder="เอกสารภาค ก"
                                    prepend-icon="mdi-paperclip"
                                    outlined
                                    rounded
                                    :rules="rules10mb"
                                    :show-size="1000"
                                  >
                                    <template
                                      v-slot:selection="{ index, text }"
                                    >
                                      <v-chip
                                        v-if="index < 2"
                                        color="deep-purple accent-4"
                                        dark
                                        label
                                        small
                                      >
                                        {{ text }}
                                      </v-chip>

                                      <span
                                        v-else-if="index === 2"
                                        class="text-overline grey--text text--darken-3 mx-2"
                                      >
                                        +{{ files.length - 2 }} File(s)
                                      </span>
                                    </template>
                                  </v-file-input>
                                </v-form>
                              </div>
                            </td>
                            <td class="pa-2 text-center">
                              <v-btn
                                @click="deletesedirector_app_file_A()"
                                v-if="sedirector_apps.sedirector_app_file_A"
                                rounded
                                color="red"
                                fab
                                smalll
                                ><v-icon>mdi-delete</v-icon>
                              </v-btn>
                              <v-btn
                                v-else
                                @click="
                                  fileapplicationform_sedirector_app_file_ASubmit()
                                "
                                rounded
                                color="primary"
                              >
                                <v-icon>mdi-cloud-upload</v-icon>
                                Upload</v-btn
                              >
                            </td>
                          </tr>

                          <tr>
                            <td class="pa-2 text-center">
                              เอกสารภาค ข
                            </td>

                            <td class="pa-2">
                              <div
                                class="text-center"
                                v-if="sedirector_apps.sedirector_app_file_B"
                              >
                                <v-btn
                                  rounded
                                  color="info"
                                  @click="
                                    pdfHrvecRegisDir(
                                      sedirector_apps.sedirector_app_file_B
                                    )
                                  "
                                  ><v-icon>mdi-file-document</v-icon>
                                  ตรวจสอบ</v-btn
                                >
                              </div>
                              <div v-else>
                                <v-form
                                  ref="fileapplicationform_sedirector_app_file_B"
                                  lazy-validation
                                >
                                  <v-file-input
                                    v-model="sedirector_app_file_B"
                                    accept=".pdf"
                                    name="sedirector_app_file_B"
                                    color="deep-purple accent-4"
                                    counter
                                    label="ไฟล์ .pdf"
                                    placeholder="เอกสารภาค ข"
                                    prepend-icon="mdi-paperclip"
                                    outlined
                                    rounded
                                    :rules="rules20mb"
                                    :show-size="1000"
                                  >
                                    <template
                                      v-slot:selection="{ index, text }"
                                    >
                                      <v-chip
                                        v-if="index < 2"
                                        color="deep-purple accent-4"
                                        dark
                                        label
                                        small
                                      >
                                        {{ text }}
                                      </v-chip>

                                      <span
                                        v-else-if="index === 2"
                                        class="text-overline grey--text text--darken-3 mx-2"
                                      >
                                        +{{ files.length - 2 }} File(s)
                                      </span>
                                    </template>
                                  </v-file-input>
                                </v-form>
                              </div>
                            </td>
                            <td class="pa-2 text-center">
                              <v-btn
                                @click="deletesedirector_app_file_B()"
                                v-if="sedirector_apps.sedirector_app_file_B"
                                rounded
                                color="red"
                                fab
                                smalll
                                ><v-icon>mdi-delete</v-icon>
                              </v-btn>
                              <v-btn
                                v-else
                                @click="
                                  fileapplicationform_sedirector_app_file_BSubmit()
                                "
                                rounded
                                color="primary"
                              >
                                <v-icon>mdi-cloud-upload</v-icon>
                                Upload</v-btn
                              >
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </div>
                  </div>

                  <div
                    v-if="
                      sedirector_apps.sedirector_app_image &&
                        sedirector_apps.sedirector_app_file &&
                        sedirector_apps.sedirector_app_file_A &&
                        sedirector_apps.sedirector_app_file_B
                    "
                  >
                    <v-btn
                      color="primary"
                      @click="confirm_director_appSubmit()"
                    >
                      ยืนยันการสมัคร
                    </v-btn>
                  </div>

                  <!-- <v-btn
                    color="warning"
                    outlined
                    @click="sedirector_appsSubmit((step = 5))"
                  >
                    ยกเลิกขั้นตอนนี้
                  </v-btn> -->
                </v-stepper-content>

                <v-stepper-step :complete="steps > 9" step="9">
                  สถานะการดำเนินการ
                </v-stepper-step>
                <v-stepper-content step="9">
                  <v-card outlined class="mb-5 pa-5">
                    <div class="text-center">
                      <v-timeline :dense="$vuetify.breakpoint.smAndDown">
                        <v-timeline-item color="red lighten-2" fill-dot left>
                          <v-card>
                            <v-card-title class="red lighten-2">
                              <div class="white--text font-weight-light">
                                <v-icon dark size="20">
                                  mdi-magnify
                                </v-icon>
                                ไฟล์แนบของผู้สมัคร
                              </div>
                            </v-card-title>
                            <v-container>
                              <v-row>
                                <v-col cols="12" md="12">
                                  <v-simple-table>
                                    <thead>
                                      <tr>
                                        <th
                                          style="width:20%"
                                          class="text-center"
                                        >
                                          รายการ
                                        </th>

                                        <th
                                          style="width:50%"
                                          class="text-center"
                                        >
                                          ไฟล์แนบ
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td class="pa-2 text-center">
                                          ใบสมัคร
                                        </td>
                                        <td class="pa-2">
                                          <div
                                            class="text-center"
                                            v-if="
                                              sedirector_apps.sedirector_app_file
                                            "
                                          >
                                            <v-btn
                                              rounded
                                              color="info"
                                              @click="
                                                pdfHrvecRegisDir(
                                                  sedirector_apps.sedirector_app_file
                                                )
                                              "
                                              ><v-icon
                                                >mdi-file-document</v-icon
                                              >
                                              ตรวจสอบ</v-btn
                                            >
                                          </div>
                                          <div v-else>
                                            <v-form
                                              ref="fileapplicationform_sedirector_app_file"
                                              lazy-validation
                                            >
                                              <v-file-input
                                                v-model="sedirector_app_file"
                                                accept=".pdf"
                                                name="sedirector_app_file"
                                                color="deep-purple accent-4"
                                                counter
                                                label="ไฟล์ .pdf"
                                                placeholder="ใบสมัคร"
                                                prepend-icon="mdi-paperclip"
                                                outlined
                                                rounded
                                                :rules="rules5mb"
                                                :show-size="1000"
                                              >
                                                <template
                                                  v-slot:selection="{
                                                    index,
                                                    text
                                                  }"
                                                >
                                                  <v-chip
                                                    v-if="index < 2"
                                                    color="deep-purple accent-4"
                                                    dark
                                                    label
                                                    small
                                                  >
                                                    {{ text }}
                                                  </v-chip>

                                                  <span
                                                    v-else-if="index === 2"
                                                    class="text-overline grey--text text--darken-3 mx-2"
                                                  >
                                                    +{{ files.length - 2 }}
                                                    File(s)
                                                  </span>
                                                </template>
                                              </v-file-input>
                                            </v-form>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="pa-2 text-center">
                                          เอกสารภาค ก
                                        </td>

                                        <td class="pa-2">
                                          <div
                                            class="text-center"
                                            v-if="
                                              sedirector_apps.sedirector_app_file_A
                                            "
                                          >
                                            <v-btn
                                              rounded
                                              color="info"
                                              @click="
                                                pdfHrvecRegisDir(
                                                  sedirector_apps.sedirector_app_file_A
                                                )
                                              "
                                              ><v-icon
                                                >mdi-file-document</v-icon
                                              >
                                              ตรวจสอบ</v-btn
                                            >
                                          </div>
                                          <div v-else>
                                            <v-form
                                              ref="fileapplicationform_sedirector_app_file_A"
                                              lazy-validation
                                            >
                                              <v-file-input
                                                v-model="sedirector_app_file_A"
                                                accept=".pdf"
                                                name="sedirector_app_file_A"
                                                color="deep-purple accent-4"
                                                counter
                                                label="ไฟล์ .pdf"
                                                placeholder="เอกสารภาค ก"
                                                prepend-icon="mdi-paperclip"
                                                outlined
                                                rounded
                                                :rules="rules10mb"
                                                :show-size="1000"
                                              >
                                                <template
                                                  v-slot:selection="{
                                                    index,
                                                    text
                                                  }"
                                                >
                                                  <v-chip
                                                    v-if="index < 2"
                                                    color="deep-purple accent-4"
                                                    dark
                                                    label
                                                    small
                                                  >
                                                    {{ text }}
                                                  </v-chip>

                                                  <span
                                                    v-else-if="index === 2"
                                                    class="text-overline grey--text text--darken-3 mx-2"
                                                  >
                                                    +{{ files.length - 2 }}
                                                    File(s)
                                                  </span>
                                                </template>
                                              </v-file-input>
                                            </v-form>
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td class="pa-2 text-center">
                                          เอกสารภาค ข
                                        </td>

                                        <td class="pa-2">
                                          <div
                                            class="text-center"
                                            v-if="
                                              sedirector_apps.sedirector_app_file_B
                                            "
                                          >
                                            <v-btn
                                              rounded
                                              color="info"
                                              @click="
                                                pdfHrvecRegisDir(
                                                  sedirector_apps.sedirector_app_file_B
                                                )
                                              "
                                              ><v-icon
                                                >mdi-file-document</v-icon
                                              >
                                              ตรวจสอบ</v-btn
                                            >
                                          </div>
                                          <div v-else>
                                            <v-form
                                              ref="fileapplicationform_sedirector_app_file_B"
                                              lazy-validation
                                            >
                                              <v-file-input
                                                v-model="sedirector_app_file_B"
                                                accept=".pdf"
                                                name="sedirector_app_file_B"
                                                color="deep-purple accent-4"
                                                counter
                                                label="ไฟล์ .pdf"
                                                placeholder="เอกสารภาค ข"
                                                prepend-icon="mdi-paperclip"
                                                outlined
                                                rounded
                                                :rules="rules20mb"
                                                :show-size="1000"
                                              >
                                                <template
                                                  v-slot:selection="{
                                                    index,
                                                    text
                                                  }"
                                                >
                                                  <v-chip
                                                    v-if="index < 2"
                                                    color="deep-purple accent-4"
                                                    dark
                                                    label
                                                    small
                                                  >
                                                    {{ text }}
                                                  </v-chip>

                                                  <span
                                                    v-else-if="index === 2"
                                                    class="text-overline grey--text text--darken-3 mx-2"
                                                  >
                                                    +{{ files.length - 2 }}
                                                    File(s)
                                                  </span>
                                                </template>
                                              </v-file-input>
                                            </v-form>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </v-simple-table>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card>
                        </v-timeline-item>
                        <v-timeline-item color="red lighten-2" fill-dot right>
                          <v-card>
                            <v-card-title class="red lighten-2">
                              <div class="white--text font-weight-light">
                                <v-icon dark size="20">
                                  mdi-magnify
                                </v-icon>
                                ประกาศรายชื่อผู้มีสิทธิเข้ารับการคัดเลือก
                                ประเมิน ภาค ก
                              </div>
                            </v-card-title>
                            <v-container>
                              <v-row>
                                <v-col cols="12" md="10">
                                  รอการประกาศรายชื่อ

                                  <h3
                                    v-if="
                                      sedirector_apps.sedirector_app_pvc_approve ===
                                        'pass' &&
                                        sedirector_apps.sedirector_app_pvc_approve_ipa ===
                                          'notice'
                                    "
                                    class="success--text"
                                  >
                                    ผ่านคุณสมบัติผู้สมัคร
                                  </h3>
                                  <h3
                                    v-else-if="
                                      sedirector_apps.sedirector_app_pvc_approve ===
                                        'fail' &&
                                        sedirector_apps.sedirector_app_pvc_approve_ipa ===
                                          'notice'
                                    "
                                    class="red--text"
                                  >
                                    ไม่ผ่านคุณสมบัติ
                                  </h3>
                                </v-col>
                                <v-col class="hidden-sm-and-down" md="2">
                                  <v-icon
                                    size="64"
                                    v-if="
                                      sedirector_apps.sedirector_app_pvc_approve ===
                                        'pass' &&
                                        sedirector_apps.sedirector_app_pvc_approve_ipa ===
                                          'notice'
                                    "
                                    color="success"
                                  >
                                    mdi-account-check
                                  </v-icon>
                                  <v-icon size="64" v-else>
                                    mdi-account-remove
                                  </v-icon>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card>
                        </v-timeline-item>

                        <v-timeline-item
                          color="amber lighten-1"
                          fill-dot
                          left
                          small
                        >
                          <v-card>
                            <v-card-title class="amber lighten-1 justify-end">
                              <div class=" mr-4 white--text font-weight-light">
                                วิทยาลัยต้นสังกัด
                              </div>
                              <v-icon dark size="42">
                                mdi-message-processing
                              </v-icon>
                            </v-card-title>
                            <v-container>
                              <v-row>
                                <v-col class="hidden-sm-and-down" md="2">
                                  <v-icon size="64">
                                    mdi-file-check
                                  </v-icon>
                                </v-col>
                                <v-col cols="12" md="10">
                                  รายงานผลการประเมิน ภาค ก. จาก กรรมการ
                                  ระดับสถานศึกษา ต้นสังกัด
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card>
                        </v-timeline-item>

                        <v-timeline-item color="cyan lighten-1" fill-dot right>
                          <v-card>
                            <v-card-title class="cyan lighten-1">
                              <v-icon class="mr-4" dark size="42">
                                mdi-domain
                              </v-icon>
                              <h2 class=" white--text font-weight-light">
                                ส่วนกลาง สอศ.
                              </h2>
                            </v-card-title>
                            <v-container>
                              <v-row>
                                <v-col cols="12" md="10">
                                  ประกาศรายชื่อผู้มีคุณสมบัติสอบ ภาค ข และ ภาค ค
                                </v-col>
                                <v-col class="hidden-sm-and-down" md="2">
                                  <v-icon size="64">
                                    mdi-account-settings
                                  </v-icon>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card>
                        </v-timeline-item>

                        <v-timeline-item
                          color="amber lighten-1"
                          fill-dot
                          left
                          small
                        >
                          <v-card>
                            <v-card-title class="amber lighten-1 justify-end">
                              <div class=" mr-4 white--text font-weight-light">
                                สำนักงานอาชีวศึกษาจังหวัด
                              </div>
                              <v-icon dark size="42">
                                mdi-message-processing
                              </v-icon>
                            </v-card-title>
                            <v-container>
                              <v-row>
                                <v-col class="hidden-sm-and-down" md="2">
                                  <v-icon size="64">
                                    mdi-file-check
                                  </v-icon>
                                </v-col>
                                <v-col cols="12" md="10">
                                  รายงานผลการประเมิน ภาค ข. จาก กรรมการ
                                  สำนักงานอาชีวศึกษาจังหวัด
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card>
                        </v-timeline-item>

                        <v-timeline-item color="cyan lighten-1" fill-dot right>
                          <v-card>
                            <v-card-title class="cyan lighten-1">
                              <v-icon class="mr-4" dark size="42">
                                mdi-domain
                              </v-icon>
                              <h2 class=" white--text font-weight-light">
                                ส่วนกลาง สอศ.
                              </h2>
                            </v-card-title>
                            <v-container>
                              <v-row>
                                <v-col cols="12" md="10">
                                  สัมภาษณ์ ภาค ค
                                </v-col>
                                <v-col class="hidden-sm-and-down" md="2">
                                  <v-icon size="64">
                                    mdi-account-settings
                                  </v-icon>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card>
                        </v-timeline-item>

                        <v-timeline-item color="cyan lighten-1" fill-dot right>
                          <v-card>
                            <v-card-title class="cyan lighten-1">
                              <v-icon class="mr-4" dark size="42">
                                mdi-domain
                              </v-icon>
                              <h2 class=" white--text font-weight-light">
                                ส่วนกลาง สอศ.
                              </h2>
                            </v-card-title>
                            <v-container>
                              <v-row>
                                <v-col cols="12" md="10">
                                  ประกาศรายชื่อผู้ผ่านการคัดเลือก
                                </v-col>
                                <v-col class="hidden-sm-and-down" md="2">
                                  <v-icon size="64">
                                    mdi-account-settings
                                  </v-icon>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card>
                        </v-timeline-item>

                        <v-timeline-item
                          color="red lighten-1"
                          fill-dot
                          left
                          small
                        >
                          <v-card>
                            <v-card-title class="red lighten-1 justify-end">
                              <h2 class=" mr-4 white--text font-weight-light">
                                ส่วนกลาง สอศ.
                              </h2>
                              <v-icon dark size="42">
                                mdi-domain
                              </v-icon>
                            </v-card-title>
                            <v-container>
                              <v-row>
                                <v-col class="hidden-sm-and-down" md="2">
                                  <v-icon size="64">
                                    mdi-server-network
                                  </v-icon>
                                </v-col>
                                <v-col cols="12" md="10">
                                  รายงานตัวเลือกสถานศึกษา
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card>
                        </v-timeline-item>

                        <v-timeline-item color="green lighten-1" fill-dot right>
                          <v-card>
                            <v-card-title class="green lighten-1">
                              <v-icon class="mr-4" dark size="42">
                                mdi-domain
                              </v-icon>
                              <h2 class=" white--text font-weight-light">
                                ส่วนกลาง สอศ.
                              </h2>
                            </v-card-title>
                            <v-container>
                              <v-row>
                                <v-col cols="12" md="10">
                                  รายงานตัว ณ สถานศึกษา
                                </v-col>
                                <v-col class="hidden-sm-and-down" md="2">
                                  <v-icon size="64">
                                    mdi-account-star
                                  </v-icon>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card>
                        </v-timeline-item>
                      </v-timeline>
                    </div>
                  </v-card>
                </v-stepper-content>
              </v-stepper>
            </v-col>
            <v-col md="1"></v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6"></v-col>
      </v-row>
    </v-card>

    <!-- V-model pdfHrvecRegisDirdialog -->
    <v-layout row justify-center>
      <v-dialog v-model="pdfHrvecRegisDirdialog" max-width="80%">
        <v-card class="" elevation="2">
          <embed
            :src="'/HrvecRegisDir/' + pdffiles"
            width="100%"
            height="700px"
          />
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import pdfMake from "pdfmake";
import { async } from "q";

export default {
  name: "HRvecSedirectorApp",

  data() {
    return {
      ApiKey: "HRvec2021",
      loading: false,
      sedirector_app_image: null,
      sedirector_app_file: null,
      sedirector_app_file_A: null,
      sedirector_app_file_B: null,
      steps: 1,
      rules1mb: [
        value => !value || value.size < 1048576 || "ไฟล์ไม่เกิน 1 MB / 1024 KB",
        value => !!value || ""
      ],
      rules5mb: [
        value =>
          !value || value.size < 5242880 || "ไฟล์ไม่เกิน 5 MB / 5,120 KB",
        value => !!value || ""
      ],
      rules10mb: [
        value =>
          !value || value.size < 10485760 || "ไฟล์ไม่เกิน 10 MB / 10,240 KB",
        value => !!value || ""
      ],
      rules15mb: [
        value =>
          !value || value.size < 15728640 || "ไฟล์ไม่เกิน 15 MB / 15,360 KB",
        value => !!value || ""
      ],
      rules20mb: [
        value =>
          !value || value.size < 20971520 || "ไฟล์ไม่เกิน 20 MB / 20,480 KB",
        value => !!value || ""
      ],

      users: [],
      personnel_educations: [],
      period_directors: [],
      radios: null,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000),
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      addsedirector: {},
      get_Appoint5_Ages: [],
      get_Appoint7_Ages: [],

      souths: null,
      sedirector_app_type: "inside",
      sedirector_apps: [],
      positions: [
        { text: "ลูกจ้างชั่วคราว", value: "ลูกจ้างชั่วคราว" },
        { text: "ลูกจ้างประจำ", value: "ลูกจ้างประจำ" },
        { text: "ครูอัตราจ้าง", value: "ครูอัตราจ้าง" },
        { text: "พนักงานราชการ", value: "พนักงานราชการ" },
        { text: "ครูผู้ช่วย", value: "ครูผู้ช่วย" },
        { text: "ครู", value: "ครู" }
      ],
      colleges: [],
      pdfHrvecRegisDirdialog: false,
      pdffiles: "",
      sedirector_appsCounts: [],
      baseUrl: window.location.origin,
      modal: false,
      modal2: false,
      modal3: false,
      modal4: false,
      modal5: false
    };
  },

  async mounted() {
    await this.personnel_temporaryQuery();
    await this.period_directorQuery();
    await this.personnel_educationsQueryAll();
    await this.sedirector_appQuery();
    await this.collegeSouthQuery();
    await this.sedirector_appCount();
  },

  methods: {
    async pdfHrvecRegisDir(pdffile) {
      this.pdffiles = "";
      this.pdffiles = pdffile;
      this.pdfHrvecRegisDirdialog = true;
    },
    async collegeSouthQuery() {
      let result = await this.$http.post("college.php", {
        ApiKey: this.ApiKey,
        collegeSouth: "1"
      });
      this.colleges = result.data;
    },
    async personnel_temporaryQuery() {
      let useression = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: useression.id_card
      });
      this.users = result.data;
      let level_rang = parseInt(this.users.rang_level);
      let pt_licencedr_file = String(this.users.pt_licencedr_file);
      let pt_enddate = this.users.pt_enddate;
      let date_app_positonnow = String(this.users.date_app_positonnow);

      if (pt_licencedr_file.length >= 10) {
      } else {
        Swal.fire({
          icon: "error",
          title:
            "ไม่ปรากฎใบอนุญาตประกอบวิชาชีพผู้บริหาร/ท่านยังไม่ได้ปรับปรุงข้อมูลใบอนุญาต",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(async result => {
          if (result.isConfirmed) {
            this.$router.push("/UserProfile");
          }
        });
      }

      if (date_app_positonnow.length > 8) {
      } else {
        Swal.fire({
          icon: "error",
          title:
            "ให้งานบุคลากรสถานศึกษาของท่านดำเนินการเกี่ยวกับวันที่ปฏิบัติงาน ณ สถานศึกษาปัจจุบันให้เรียบร้อย",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(async result => {
          if (result.isConfirmed) {
            this.$router.push("/UserProfile");
          }
        });
      }

      if (
        level_rang == 2 ||
        level_rang == 3 ||
        level_rang == 4 ||
        level_rang == 5
      ) {
      } else {
        Swal.fire({
          icon: "error",
          title: " ท่านขาดคุณสมบัติในการสมัคร",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(async result => {
          if (result.isConfirmed) {
            this.$router.push("/UserProfile");
          }
        });
      }
    },

    async timeCheck() {
      let dateNow = new Date();
      let dateStop = new Date(
        this.period_directors.period_sedirector_stop + " 16:30:00"
      );
      this.dateNows = dateNow.getTime();
      this.dateStops = dateStop.getTime();
      if (this.dateNows > this.dateStops) {
        await this.periodsedirectorUpdate();
      } else {
      }
    },

    async periodsedirectorUpdate() {
      this.periods.ApiKey = this.ApiKey;
      this.periods.period_sedirector_enable = "0";
      await this.$http.post("period_sedirector.update.php", this.periods);
    },

    async pdfmakerOpen() {
      let data = this.users.id_card;
      window.open("#/user/print_app_sedirector/" + data, "_blank");
    },
    async deletesedirector_app_file_B() {
      await this.sweetAlertLoading();
      this.sedirector_apps.ApiKey = this.ApiKey;
      let result_delete = await this.$http.post("deletefile.php", {
        ApiKey: this.ApiKey,
        filename:
          "../HrvecRegisDir/" + this.sedirector_apps.sedirector_app_file_B
      });

      this.sedirector_apps.sedirector_app_file_B = "";
      let result = await this.$http.post(
        "sedirector_app.update.php",
        this.sedirector_apps
      );

      if (result.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.sedirector_appQuery();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }

      Swal.close();
    },
    async fileapplicationform_sedirector_app_file_BSubmit() {
      await this.sweetAlertLoading();
      this.sedirector_apps.ApiKey = this.ApiKey;
      this.sedirector_apps.sedirector_app_year = this.period_directors.period_sedirector_year;
      this.sedirector_apps.sedirector_app_time = this.period_directors.period_sedirector_times;
      this.sedirector_apps.sedirector_app_idcard = this.users.id_card;
      let uploaded = null;
      let result = "";
      if (this.$refs.fileapplicationform_sedirector_app_file_B.validate()) {
        if (this.sedirector_app_file_B != "") {
          let formData = new FormData();
          let filename =
            this.period_directors.period_sedirector_year +
            this.period_directors.period_sedirector_times +
            this.users.id_card +
            this.time_stamp +
            "." +
            "sedirectb.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.sedirector_app_file_B);
          formData.append("filename", "../HrvecRegisDir/" + filename);

          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          this.sedirector_apps.sedirector_app_file_B = filename;
          uploaded = true;
        } else {
          uploaded = false;
        }
      } else {
        upload = false;
      }

      let resultup = await this.$http.post(
        "sedirector_app.update.php",
        this.sedirector_apps
      );

      if (resultup.data.status == true) {
        Swal.fire({
          title: "ดำเนินการข้อมูลเรียบร้อย",
          icon: "success",
          showConfirmButton: false,
          timer: 1500
        });
        await this.sedirector_appQuery();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      Swal.close();
    },
    async deletesedirectorSubmit() {
      Swal.fire({
        title: "ยกเลิกรายการเริ่มต้นใหม่",
        text: "ขั้นตอนนี้เป็นขั้นตอนยกเลิกการสมัคร",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.sedirector_apps.ApiKey = this.ApiKey;
          let result = await this.$http.post(
            "sedirector_app.delete.php",
            this.sedirector_apps
          );
          if (result.data.status == true) {
            Swal.fire({
              title: "ดำเนินการข้อมูลเรียบร้อย",
              icon: "success",
              showConfirmButton: false,
              timer: 1500
            });
            await this.sedirector_appQuery();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },

    async deletesedirector_app_image() {
      await this.sweetAlertLoading();
      this.sedirector_apps.ApiKey = this.ApiKey;

      let result_delete = await this.$http.post("deletefile.php", {
        ApiKey: this.ApiKey,
        filename:
          "../HrvecRegisDir/" + this.sedirector_apps.sedirector_app_image
      });

      this.sedirector_apps.sedirector_app_image = "";
      let result = await this.$http.post(
        "sedirector_app.update.php",
        this.sedirector_apps
      );

      if (result.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.sedirector_appQuery();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      Swal.close();
    },

    async deletesedirector_app_file_A() {
      await this.sweetAlertLoading();
      this.sedirector_apps.ApiKey = this.ApiKey;

      let result_delete = await this.$http.post("deletefile.php", {
        ApiKey: this.ApiKey,
        filename:
          "../HrvecRegisDir/" + this.sedirector_apps.sedirector_app_file_A
      });

      this.sedirector_apps.sedirector_app_file_A = "";
      let result = await this.$http.post(
        "sedirector_app.update.php",
        this.sedirector_apps
      );

      if (result.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.sedirector_appQuery();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      Swal.close();
    },

    async fileapplicationform_sedirector_app_file_ASubmit() {
      await this.sweetAlertLoading();

      this.sedirector_apps.ApiKey = this.ApiKey;
      this.sedirector_apps.sedirector_app_year = this.period_directors.period_sedirector_year;
      this.sedirector_apps.sedirector_app_time = this.period_directors.period_sedirector_times;
      this.sedirector_apps.sedirector_app_idcard = this.users.id_card;
      let uploaded = null;
      let result = "";
      if (this.$refs.fileapplicationform_sedirector_app_file_A.validate()) {
        if (this.sedirector_app_file_A != "") {
          let formData = new FormData();
          let filename =
            this.period_directors.period_sedirector_year +
            this.period_directors.period_sedirector_times +
            this.users.id_card +
            this.time_stamp +
            "." +
            "sedirecta.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.sedirector_app_file_A);
          formData.append("filename", "../HrvecRegisDir/" + filename);

          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          this.sedirector_apps.sedirector_app_file_A = filename;
          uploaded = true;
        } else {
          uploaded = false;
        }
      } else {
        upload = false;
      }

      let resultup = await this.$http.post(
        "sedirector_app.update.php",
        this.sedirector_apps
      );

      if (resultup.data.status == true) {
        Swal.fire({
          title: "ดำเนินการข้อมูลเรียบร้อย",
          icon: "success",
          showConfirmButton: false,
          timer: 1500
        });
        await this.sedirector_appQuery();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      Swal.close();
    },

    async deletesedirector_app_file() {
      await this.sweetAlertLoading();

      this.sedirector_apps.ApiKey = this.ApiKey;

      let result_delete = await this.$http.post("deletefile.php", {
        ApiKey: this.ApiKey,
        filename: "../HrvecRegisDir/" + this.sedirector_apps.sedirector_app_file
      });

      this.sedirector_apps.sedirector_app_file = "";
      let result = await this.$http.post(
        "sedirector_app.update.php",
        this.sedirector_apps
      );

      if (result.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.sedirector_appQuery();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      Swal.close();
    },

    async fileapplicationform_sedirector_app_imageSubmit() {
      await this.sweetAlertLoading();
      this.sedirector_apps.ApiKey = this.ApiKey;
      this.sedirector_apps.sedirector_app_year = this.period_directors.period_sedirector_year;
      this.sedirector_apps.sedirector_app_time = this.period_directors.period_sedirector_times;
      this.sedirector_apps.sedirector_app_idcard = this.users.id_card;
      let uploaded = null;
      let result = "";
      if (this.$refs.fileapplicationform_sedirector_app_image.validate()) {
        if (this.sedirector_app_image != "") {
          let formData = new FormData();
          let filename =
            this.period_directors.period_sedirector_year +
            this.period_directors.period_sedirector_times +
            this.users.id_card +
            this.time_stamp +
            "." +
            "sedirect.jpg";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.sedirector_app_image);
          formData.append("filename", "../HrvecRegisDir/" + filename);

          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          this.sedirector_apps.sedirector_app_image = filename;
          uploaded = true;
        } else {
          uploaded = false;
        }
      }

      let resultup = await this.$http.post(
        "sedirector_app.update.php",
        this.sedirector_apps
      );
      if (resultup.data.status == true) {
        Swal.fire({
          title: "ดำเนินการข้อมูลเรียบร้อย",
          icon: "success",
          showConfirmButton: false,
          timer: 1500
        });
        await this.sedirector_appQuery();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      Swal.close();
    },

    async fileapplicationform_sedirector_app_fileSubmit() {
      await this.sweetAlertLoading();

      this.sedirector_apps.ApiKey = this.ApiKey;
      this.sedirector_apps.sedirector_app_year = this.period_directors.period_sedirector_year;
      this.sedirector_apps.sedirector_app_time = this.period_directors.period_sedirector_times;
      this.sedirector_apps.sedirector_app_idcard = this.users.id_card;
      let uploaded = null;
      let result = "";
      if (this.$refs.fileapplicationform_sedirector_app_file.validate()) {
        if (this.sedirector_app_file != "") {
          let formData = new FormData();
          let filename =
            this.period_directors.period_sedirector_year +
            this.period_directors.period_sedirector_times +
            this.users.id_card +
            this.time_stamp +
            "." +
            "sedirect.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.sedirector_app_file);
          formData.append("filename", "../HrvecRegisDir/" + filename);

          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          this.sedirector_apps.sedirector_app_file = filename;
          uploaded = true;
        } else {
          uploaded = false;
        }
      }

      let resultup = await this.$http.post(
        "sedirector_app.update.php",
        this.sedirector_apps
      );

      if (resultup.data.status == true) {
        Swal.fire({
          title: "ดำเนินการข้อมูลเรียบร้อย",
          icon: "success",
          showConfirmButton: false,
          timer: 1500
        });
        await this.sedirector_appQuery();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      Swal.close();
    },

    async confirm_director_appSubmit() {
      Swal.fire({
        title: "ยืนยันการสมัคร",
        text: "ขั้นตอนนี้เป็นขั้นตอนสุดท้าย",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          await this.sedirector_appCount();
          let idno;
          if (this.sedirector_apps.sedirector_app_type == "inside") {
            idno = "10";
          } else if (this.sedirector_apps.sedirector_app_type == "outside") {
            idno = "20";
          }
          let countid = parseInt(this.sedirector_appsCounts) + 1;

          this.sedirector_apps.ApiKey = this.ApiKey;
          this.sedirector_apps.sedirector_app_status = 8;

          this.sedirector_apps.sedirector_app_idno = idno + "00" + countid;
          let dateTime = String(new Date());
          this.sedirector_apps.sedirector_app_datetime = dateTime;

          let resultup = await this.$http.post(
            "sedirector_app.update.php",
            this.sedirector_apps
          );

          if (resultup.data.status == true) {
            Swal.fire({
              title: "ดำเนินการข้อมูลเรียบร้อย",
              icon: "success",
              showConfirmButton: false,
              timer: 1500
            });
            await this.sedirector_appQuery();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },

    async sedirector_appsSubmit(step) {
      this.sedirector_apps.ApiKey = this.ApiKey;
      this.sedirector_apps.sedirector_app_year = this.period_directors.period_sedirector_year;
      this.sedirector_apps.sedirector_app_time = this.period_directors.period_sedirector_times;
      this.sedirector_apps.sedirector_app_idcard = this.users.id_card;
      this.sedirector_apps.sedirector_app_agework = this.get_gov_Age;
      this.sedirector_apps.sedirector_age_position = this.get_Appoint_Age;

      if (this.sedirector_apps.sedirector_app_position == "teach") {
        this.sedirector_apps.sedirector_app_position = "teach";
        this.sedirector_apps.sedirector_app_start = this.get_app_dmy;
        this.sedirector_apps.sedirector_app_end = this.date_end_cal;
        this.sedirector_apps.sedirector_age_position_work5 = this.get_Appoint_Age;
      } else if (this.sedirector_apps.sedirector_app_position == "head") {
        this.sedirector_apps.sedirector_app_position = "head";
        this.sedirector_apps.sedirector_age_position_work5 = this.get_Appoint5_Ages;
      } else if (this.sedirector_apps.sedirector_app_position == "director") {
        this.sedirector_apps.sedirector_app_position = "director";
        this.sedirector_apps.sedirector_age_position_work5 = this.get_Appoint5_Ages;
      }

      if (this.souths == "ever")
        this.sedirector_apps.sedirector_app_south = "ever";
      else if (this.souths == "never")
        this.sedirector_apps.sedirector_app_south = "never";

      if (step == 6 && this.sedirector_apps.sedirector_app_type == "inside") {
        step = 7;
      }

      this.sedirector_apps.sedirector_app_status = step;
      let resultup = await this.$http.post(
        "sedirector_app.update.php",
        this.sedirector_apps
      );

      if (resultup.data.status == true) {
        Swal.fire({
          title: "ดำเนินการข้อมูลเรียบร้อย",
          icon: "success",
          showConfirmButton: false,
          timer: 1500
        });
        await this.sedirector_appQuery();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
    },

    async addsedirectorSubmit() {
      this.addsedirector.ApiKey = this.ApiKey;
      this.addsedirector.sedirector_app_year = this.period_directors.period_sedirector_year;
      this.addsedirector.sedirector_app_time = this.period_directors.period_sedirector_times;
      this.addsedirector.sedirector_app_ptype = 3;
      this.addsedirector.sedirector_app_idcard = this.users.id_card;
      this.addsedirector.sedirector_app_idref = this.sedirector_app_idref;
      this.addsedirector.sedirector_app_type = this.sedirector_app_type;
      this.addsedirector.sedirector_app_college = this.users.college_code;
      this.addsedirector.sedirector_app_status = "1";
      Swal.fire({
        title: "สร้างใบสมัครชั่วคราว",
        text: "ตำแหน่งผู้อำนวยการสถานศึกษา",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          let resultins = await this.$http.post(
            "sedirector_app.insert.php",
            this.addsedirector
          );

          if (resultins.data.status == true) {
            Swal.fire({
              title: "ดำเนินการข้อมูลเรียบร้อย",
              icon: "success",
              showConfirmButton: false,
              timer: 1500
            });
            await this.sedirector_appQuery();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },
    async sedirector_appQuery() {
      let result = await this.$http.post("sedirector_app.php", {
        ApiKey: this.ApiKey,
        sedirector_app_year: this.period_directors.period_sedirector_year,
        sedirector_app_time: this.period_directors.period_sedirector_times,
        sedirector_app_idcard: this.users.id_card
      });
      this.sedirector_apps = result.data;
      if (this.sedirector_apps.sedirector_app_status >= 1) {
        this.steps = parseInt(this.sedirector_apps.sedirector_app_status) + 1;
      } else {
        this.steps = 1;
      }
    },

    async sedirector_appCount() {
      let result = await this.$http.post("sedirector_app.php", {
        ApiKey: this.ApiKey,
        sedirector_app_year: this.period_directors.period_sedirector_year,
        sedirector_app_time: this.period_directors.period_sedirector_times
      });
      let data = result.data;
      let counts = 0;
      data.forEach(value => {
        if (value.sedirector_app_status == 8) {
          counts += 1;
        }
      });
      this.sedirector_appsCounts = counts;
    },

    async period_directorQuery() {
      let result_period;
      result_period = await this.$http.post("period_sedirector.php", {
        ApiKey: this.ApiKey,
        period_sedirector_enable: "1",
        period_sedirector_id: "301"
      });
      this.period_directors = result_period.data;
    },

    async personnel_educationsQueryAll() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("personnel_education.php", {
          ApiKey: this.ApiKey,
          id_card: userSession.id_card
        })
        .finally(() => (this.loading = false));
      this.personnel_educations = result.data;

      let data = this.personnel_educations;
      let counts = 0;
      data.forEach(value => {
        counts += 1;
      });
      if (counts >= 1) {
      } else {
        Swal.fire({
          icon: "error",
          title:
            "ไม่พบรายการวุฒิการศึกษาของท่าน กรุณากรอกข้อมูลวุฒิการศึกษาของท่าน",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(async result => {
          if (result.isConfirmed) {
            this.$router.push("/personnel_education");
          }
        });
      }
    },

    get_Appoin7_Age() {
      let startDate = new Date(
        new Date(String(this.sedirector_apps.sedirector_app_southdatebegin))
       
      );
      const endingDate = new Date(String(this.sedirector_apps.sedirector_app_southend))
      let endDate = new Date(endingDate);
      if (startDate > endDate) {
        const swap = startDate;
        startDate = endDate;
        endDate = swap;
      }
      const startYear = startDate.getFullYear();

      // Leap years
      const february =
        (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
          ? 29
          : 28;
      const daysInMonth = [
        31,
        february,
        31,
        30,
        31,
        30,
        31,
        31,
        30,
        31,
        30,
        31
      ];

      let yearDiff = endDate.getFullYear() - startYear;
      let monthDiff = endDate.getMonth() - startDate.getMonth();
      let dayDiff = endDate.getDate() - startDate.getDate();

      if (monthDiff < 0) {
        yearDiff--;
        monthDiff += 12;
      }

      if (dayDiff < 0) {
        if (monthDiff > 0) {
          monthDiff--;
        } else {
          yearDiff--;
          monthDiff = 11;
        }
        dayDiff += daysInMonth[startDate.getMonth()];
      }

      let age = yearDiff + " ปี " + monthDiff + " เดือน " + dayDiff + " วัน";

      this.get_Appoint7_Ages = age;
    },

    get_Appoint5_Age() {
      let startDate = new Date(
        new Date(String(this.sedirector_apps.sedirector_app_start))
      );
      const endingDate = new Date(String(this.sedirector_apps.sedirector_app_end))
      let endDate = new Date(endingDate);
      if (startDate > endDate) {
        const swap = startDate;
        startDate = endDate;
        endDate = swap;
      }
      const startYear = startDate.getFullYear();

      // Leap years
      const february =
        (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
          ? 29
          : 28;
      const daysInMonth = [
        31,
        february,
        31,
        30,
        31,
        30,
        31,
        31,
        30,
        31,
        30,
        31
      ];

      let yearDiff = endDate.getFullYear() - startYear;
      let monthDiff = endDate.getMonth() - startDate.getMonth();
      let dayDiff = endDate.getDate() - startDate.getDate();

      if (monthDiff < 0) {
        yearDiff--;
        monthDiff += 12;
      }

      if (dayDiff < 0) {
        if (monthDiff > 0) {
          monthDiff--;
        } else {
          yearDiff--;
          monthDiff = 11;
        }
        dayDiff += daysInMonth[startDate.getMonth()];
      }

      let age = yearDiff + " ปี " + monthDiff + " เดือน " + dayDiff + " วัน";

      this.get_Appoint5_Ages = age;
    },
    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    }
  },
  computed: {
    sedirector_app_idref() {
      let years = this.period_directors.period_sedirector_year;
      let times = this.period_directors.period_sedirector_times;
      let ids = this.users.id_card;
      let result = ids + years + times;
      return result;
    },
    time_stamp() {
      const d = new Date();
      let time = Math.floor(d.getTime() / 1000);
      return time;
    },
    date_today_cal() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();

      today = yyyy + "-" + mm + "-" + dd;
      return today;
    },
    datetime_save() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();

      let hh = today.getHours();
      let mn = today.getMinutes();
      let se = today.getSeconds();

      today = yyyy + "-" + mm + "-" + dd + " " + hh + ":" + mn + ":" + se;
      return today;
    },
    get_app_dmy() {
      let DOB =
        this.users.appoin_year -
        543 +
        "-" +
        this.users.appoin_month +
        "-" +
        this.users.appoin_day;
      return DOB;
    },
    get_gov_Age() {
      let yearsApp = parseInt(this.users.appoin_year) - 543;
      let monthApp = this.users.appoin_month;
      let dayApp = this.users.appoin_day;
      let dateTimeApp = String(yearsApp + "-" + monthApp + "-" + dayApp);

      let startDate = new Date(
        new Date(dateTimeApp)
      );
      const endingDate = new Date(
        String(this.period_directors.period_sedirector_dmycal)
      )
      let endDate = new Date(endingDate);
      if (startDate > endDate) {
        const swap = startDate;
        startDate = endDate;
        endDate = swap;
      }
      const startYear = startDate.getFullYear();

      // Leap years
      const february =
        (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
          ? 29
          : 28;
      const daysInMonth = [
        31,
        february,
        31,
        30,
        31,
        30,
        31,
        31,
        30,
        31,
        30,
        31
      ];

      let yearDiff = endDate.getFullYear() - startYear;
      let monthDiff = endDate.getMonth() - startDate.getMonth();
      let dayDiff = endDate.getDate() - startDate.getDate();

      if (monthDiff < 0) {
        yearDiff--;
        monthDiff += 12;
      }

      if (dayDiff < 0) {
        if (monthDiff > 0) {
          monthDiff--;
        } else {
          yearDiff--;
          monthDiff = 11;
        }
        dayDiff += daysInMonth[startDate.getMonth()];
      }

      let age = yearDiff + " ปี " + monthDiff + " เดือน " + dayDiff + " วัน";
      return age;
    },
    get_Appoint_Age() {
      let startDate = new Date(
        new Date(String(this.period_directors.period_sedirector_dmycal))
      );
      const endingDate = new Date(String(this.users.date_app_positonnow))
      let endDate = new Date(endingDate);
      if (startDate > endDate) {
        const swap = startDate;
        startDate = endDate;
        endDate = swap;
      }
      const startYear = startDate.getFullYear();

      // Leap years
      const february =
        (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
          ? 29
          : 28;
      const daysInMonth = [
        31,
        february,
        31,
        30,
        31,
        30,
        31,
        31,
        30,
        31,
        30,
        31
      ];

      let yearDiff = endDate.getFullYear() - startYear;
      let monthDiff = endDate.getMonth() - startDate.getMonth();
      let dayDiff = endDate.getDate() - startDate.getDate();

      if (monthDiff < 0) {
        yearDiff--;
        monthDiff += 12;
      }

      if (dayDiff < 0) {
        if (monthDiff > 0) {
          monthDiff--;
        } else {
          yearDiff--;
          monthDiff = 11;
        }
        dayDiff += daysInMonth[startDate.getMonth()];
      }

      let age = yearDiff + " ปี " + monthDiff + " เดือน " + dayDiff + " วัน";
      return age;
    },

    date_month_cal() {
      let dd = "1";
      let mm = "10";
      let yyyy = parseInt(this.period_directors.period_sedirector_year) - 543;
      let date = yyyy + "-" + mm + "-" + dd;
      return date;
    },
    date_end_cal() {
      let dd = "30";
      let mm = "9";
      let yyyy = parseInt(this.period_directors.period_sedirector_year) - 543;
      let date = yyyy + "-" + mm + "-" + dd;
      return date;
    },

    checkwork() {
      let selects = this.sedirector_apps.sedirector_app_position;
      if (selects == "teach") {
        return (this.addsedirector.sedirector_app_start = "");
        return (this.addsedirector.sedirector_app_end = "");
      }
    }
  },
  watch: {
    async checkwork() {
      let selects = this.sedirector_apps.sedirector_app_position;
      if (selects == "teach") {
        this.addsedirector.sedirector_app_start = "";
        this.addsedirector.sedirector_app_end = "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.bold16 {
  font-size: 16pt;
  font-weight: bold;
}

.bold14 {
  font-size: 14pt;
  font-weight: bold;
}

.normal16 {
  font-size: 16pt;
}

.normal14 {
  font-size: 14pt;
}

.bluetext {
  color: blue;
}
</style>
